(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

// +++++add year to footer ++++
var date = new Date();
document.getElementById("yearfooter").textContent = date.getFullYear();
// ---- End add year to footer ----

//++++++++ Arrow Go Top ++++++++++
var $window = $(window);
var $slideAd = $('#go-top');
var endZone = $('#brief1').offset().top - $window.height() - 500;
$window.on('scroll', function () {
    if (endZone < $window.scrollTop()) {
        $slideAd.animate({
            'right': '10px'
        }, 250);
    } else {
        $slideAd.stop(true).animate({
            'right': '-95px'
        }, 250);
    }
});
//smooth scrolling
var $slideLink = $('#go-top a');
$slideLink.click(function (e) {
    e.preventDefault();
    $('body,html').animate({ scrollTop: $('#home').offset().top }, 1000);
});
///---- End Arrow Go Top ------
$(document).ready(function () {
    var scrollLink = $('.scroll');
    //smooth scrolling
    scrollLink.click(function (e) {
        e.preventDefault();
        $('body,html').animate({
            scrollTop: $(this.hash).offset().top
        }, 1000);
    });
});
//++++++++ Pop Up Contact Form ++++++++++

// function that add a class to element that is got by class name
function addClassToElem(elemClass, newClass) {
    var el = document.querySelector(elemClass);
    el.className += ' ' + newClass;
}
// get the business CTA
var bussineCTA = document.getElementById("bussinesCTA");
// Add a event on Click
bussineCTA.addEventListener('click', function (e) {
    e.preventDefault();
    addClassToElem(".wrapper", "wrapper-opacity");
    addClassToElem(".block-contact-business", "display-block");
}, false);

// close the pop up form
// function thar removes class from element
function removeClassFromElem(elemClass, removeClass) {
    var el = document.querySelector(elemClass);
    el.classList.remove(removeClass);
}
var closeBTN = document.getElementById("close-form");
closeBTN.addEventListener("click", function () {
    removeClassFromElem(".wrapper", "wrapper-opacity");
    removeClassFromElem(".block-contact-business", "display-block");
}, false);

//-------- Pop Up Contact Form ----------


/*=============================================
=            Send Email block            =
=============================================*/

$(function () {
    //start jQuery


    /*=============================================
    =           SIGN UP FORM            =
    =============================================*/
    // contact form on submit
    $("#contactForm").submit(function (e) {
        e.preventDefault();
        var formData = $("#contactForm").serialize();
        $.ajax({
            type: "POST",
            url: "assets/php/sendmail.php",
            data: formData
            // success: function () {
            //     // $('.success').fadeIn(1000);
            // }
        }).done(function (response) {
            // Make sure that the formMessages div has the 'success' class.
            $('.success').fadeIn(1000);
            $('.error').fadeOut(50);
            // Clear the form.
            $(".contact-form input[name=name]").val('');
            $(".contact-form input[name=email]").val('');
            $(".contact-form input[name=subject]").val('');
            $("textarea#messageSignUp").val('');
        }).fail(function (data) {
            // Make sure that the formMessages div has the 'error' class.
            $('.error').fadeIn(1000);
            $('.success').fadeOut(50);
        });
    });

    /*===== /End SIGN UP FORM    ======*/

    /*=============================================
    =           SUBSCRIBE Form            =
    =============================================*/
    //  on submit
    $(".subscription-form").submit(function (e) {
        e.preventDefault();
        var formData = $(".subscription-form").serialize();
        var buttonSubscr = document.getElementById("subscribe-button");
        $.ajax({
            type: "POST",
            url: "assets/php/subscribe.php",
            data: formData

        }).done(function (response) {
            // Make sure that the formMessages div has the 'success' class.
            $('.subscription-error').fadeOut(50);
            // Clear the form.
            $(".subscription-form input[name=email]").val('');
            // Change color            
            buttonSubscr.textContent = "Subscribed !";
            buttonSubscr.className += " success-subscribe";
        }).fail(function (data) {
            // Make sure that the formMessages div has the 'error' class.
            $('.subscription-error').fadeIn(1000);
            buttonSubscr.textContent = "Subscribe";
            buttonSubscr.classList.remove("success-subscribe");
        });
    });

    /*===== /End  SUBSCRIBE FORM   ======*/

    /*=============================================
    =            Form for Business            =
    =============================================*/

    //  on submit
    $(".contact-form-business").submit(function (e) {
        e.preventDefault();
        var formData = $(".contact-form-business").serialize();
        var buttonSubscr = document.getElementById("submitBusiness");
        $.ajax({
            type: "POST",
            url: "assets/php/businessemail.php",
            data: formData

        }).done(function (response) {
            // Make sure that the formMessages div has the 'success' class.
            $('.business-error').fadeOut(50);

            // $(".contact-form-business input[name=bn-email]").val('');
            // $(".contact-form-business input[name=bn-p-number]").val('');
            // function resetForm($form) {
            //     $form.find('input:text,  textarea').val('');
            //     $form.find('input:checkbox').removeAttr('checked').removeAttr('selected');
            // }
            // resetForm($('#contact-business'));
            // Reset the form.
            document.getElementById("contact-business").reset();
            // Change color            
            buttonSubscr.textContent = "Message Sent !";
            buttonSubscr.className += " success-business";
            setTimeout(function () {
                removeClassFromElem(".block-contact-business", "display-block");
                removeClassFromElem(".wrapper", "wrapper-opacity");
                buttonSubscr.textContent = "Send Message";
                buttonSubscr.classList.remove("success-business");
            }, 3000);
        }).fail(function (data) {
            // Make sure that the formMessages div has the 'error' class.
            $('.business-error').fadeIn(1000);
            buttonSubscr.textContent = "Send Message";
            buttonSubscr.classList.remove("success-business");
        });
    });

    /*=====  End of Form for Business  ======*/

    /*=============================================
    =                 What people are saying  SECTION      =
    =============================================*/
    $("#feedBackT").owlCarousel({

        navigation: false, // Show next and prev buttons
        slideSpeed: 800,
        paginationSpeed: 300,
        autoPlay: 10000,
        singleItem: true,
        loop: true,
        pagination: false
    });

    /*=====  End of What people are saying  SECTION ======*/

    /*=============================================
    =            Cookies            =
    =============================================*/
    // // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++Create Cookies

    // create cookie++++++++++++++++++++++++++++++++++++

    function createCookie(name, value, days, path, domain, secure) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            var expires = date.toGMTString();
        } else {
            var expires = "";
        }

        var cookieString = name + "=" + escape(value);
        // console.log(cookieString);

        if (expires) {
            cookieString += "; expires=" + expires;
        };
        if (path) {
            cookieString += "; path=" + escape(path);
        };
        if (domain) {
            cookieString += "; domain=" + escape(domain);
        };
        if (secure) {
            cookieString += "; secure";
        };
        document.cookie = cookieString;
    }

    // get the cookie++++++++++++++++++++++++++++++++++++
    function getCookie(cname) {
        var name = cname + "=";
        var kv = document.cookie.split(";");
        for (var id in kv) {
            var cookie = kv[id];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) == 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return "";
    }

    // function check cookie+++++++++++++++++++++++++++++++
    function checkCookie() {
        var cookieName = getCookie("acceptPolicy");
        // var location = getCookie("location");
        var elem = document.getElementById("cookie-main-wrap");
        if (elem) {
            if (cookieName != "") {
                // console.log('cookies exist');
                elem.className = "cookie-main-wrap";
            } else {
                // console.log("we need do create Cookies");
                var elem = document.getElementById("cookie-main-wrap");
                // view the block after 10 seconds
                setTimeout(function () {
                    elem.className += " showcookieSection";
                }, 4000);
            }
        }
    }
    // wait till site is loaded
    jQuery(window).load(function () {
        checkCookie();
    });

    // accept cookies
    var aAgree = document.getElementById("a-agree");
    if (aAgree) {
        aAgree.addEventListener('click', function (e) {
            e.preventDefault();
            //  console.log('test');
            createCookie("acceptPolicy", "Yes", 365); //create cookie 
            // console.log("cookies created");
            var me = document.getElementById("cookie-main-wrap");
            me.classList.remove("showcookieSection");
            me.className += " hidecookie";
            setTimeout(function () {
                me.classList.remove("hidecookie");
            }, 1500);
        }, false);
    }
    //decline cookies
    var aDecline = document.getElementById("a-decline");
    if (aDecline) {
        aDecline.addEventListener('click', function (e) {
            e.preventDefault();
            var me = document.getElementById("cookie-main-wrap");
            me.classList.remove("showcookieSection");
            me.className += " hidecookie";
            setTimeout(function () {
                me.classList.remove("hidecookie");
            }, 1500);
        }, false);
    }

    /*=====  End of Cookies  ======*/
}); // end jQuery

/*=====  /End of Send Email block  ======*/

},{}]},{},[1]);
